import { render, staticRenderFns } from "./SpanLayout.vue?vue&type=template&id=f2e4e470&scoped=true"
import script from "./SpanLayout.vue?vue&type=script&lang=js"
export * from "./SpanLayout.vue?vue&type=script&lang=js"
import style0 from "./SpanLayout.vue?vue&type=style&index=0&id=f2e4e470&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2e4e470",
  null
  
)

export default component.exports